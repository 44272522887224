import { useEffect, useState } from "react";
import "../styles/CookieModal.scss";

const cookieAcceptKey = "cookieAccept";

const CookieModal = ({ children }) => {
    const [popup, setPopup] = useState(false);

    useEffect(() => {
        const cookieAccept = localStorage.getItem(cookieAcceptKey);
        if (!cookieAccept) {
            setPopup(true);
        }
    }, []);

    const aceeptCookieStorage = () => {
        localStorage.setItem(cookieAcceptKey, 1);
        setPopup(false);
    };

    return (
        <>
            {children}
            {popup && (
                <div className="modal">
                    <p className="text">
                        ESIMUS SERVICE uses cookies to improve your experience on the site. By continuing to browse the site, you agree to
                        the use of cookies. 
                        <a href="/cookies" className={"link"}>Cookies Policy</a>
                    </p>
                    <button onClick={aceeptCookieStorage}>OK</button>
                </div>
            )}
        </>
    );
};
export default CookieModal;

import React,{useState,useEffect, useContext} from 'react'
import { LanguageContext } from '../languageContext'
import '../styles/Footer.scss'
import { Link } from "react-router-dom";
import getFetch from './functions/fetching';

const Footer = () => {
   const { lang } = useContext(LanguageContext)
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const fethUrl = `https://esimus-property.com/app/api/footer?locale=${lang}&populate[powered][populate][logocolor][fields]=url&populate[powered][populate][logoblack][fields]=url&populate[logo][populate][img][fields]=url&populate[navv2][fields]&populate[policy][fields]`
    
    useEffect(() => {
      
      getFetch(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)
    }, [lang])
    if (error) {
      return <div>Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className='fetch-loader'></div>;
    } else {
  return (
   <footer className="footer">
      <div className="container">
         <div className="footer__body">
            <div className="footer__top-flex">
               <div className="footer__logo">
               <Link to={items.data.attributes.logo.link}>
                  {/* <img src={"https://esimus-property.com/app"+items.data.attributes.logo.img.data.attributes.url} alt="logo" /> */}
                  <img src={"https://esimus-property.com/app/uploads/esimus_service_logo_dark_18e12b969d.png"} alt="logo" />
               </Link>
               </div>
               <nav className="footer__menu">
                  <ul>
                     {
                        items.data.attributes.navv2.map(item=>(
                           <li key={item.id}><Link to={item.link}>{item.text}</Link></li>
                        ))
                     }
                  </ul>
               </nav>
            </div>
            <div className="footer__bottom-flex">
               <ul className="footer__policy">
                  {
                     items.data.attributes.policy.map(item=>(
                        <li key={item.id}><a  href={item.link}>{item.text}</a></li>
                     ))
                  }
                  
               </ul>
               <p className="footer__copyright">
                  {items.data.attributes.copyrite.replace('2021', new Date().getFullYear())}
               </p>
               <div className="powered-by">
                  <a href={items.data.attributes.powered.link} target="_blank">
                     <img className="powered-by__imgColor" src={"https://esimus-property.com/app"+items.data.attributes.powered.logocolor.data.attributes.url} alt="logo"/>
                     <img className="powered-by__imgBlack" src={"https://esimus-property.com/app"+items.data.attributes.powered.logoblack.data.attributes.url} alt="logo"/>
                  </a>
                           
               </div>
            </div>
         </div>
      </div>
   </footer>
  )
}
}
export default Footer
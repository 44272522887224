import React,{useEffect,useState, useContext, useRef} from 'react'
import { LanguageContext } from '../languageContext'
import '../styles/Gallery.scss'
import { useLoadingContext } from "react-router-loading";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Helmet } from 'react-helmet';
import getFetch from './functions/fetching';
import parse from 'html-react-parser'
import Layout from './Layout';

const Gallery = () => {
   const loadingContext = useLoadingContext();
   const { lang } = useContext(LanguageContext)
   const screenRef = useRef(null)
   const popupRef = useRef(null)

      useEffect(() => {
      fetch(fethUrl)
         .then(res => res.json())
         .then(result => setItems(result))
         .catch(error => {
            setError(error)
            console.log(error)
         })
         .finally(() => {
            setIsLoaded(true)
            loadingContext.done()
         })
    }, [lang])

   const [error, setError] = useState(null);
   const [isLoaded, setIsLoaded] = useState(false);
   const [items, setItems] = useState([]);
   const [popupData, setPopupData] = useState({
      name: '',
      iframe: '',
      visible: false
   })

   useEffect(() => {
      function handleClickOutsideTab(e) {
          if (screenRef.current && popupRef.current && screenRef.current.contains(e.target) && !popupRef.current.contains(e.target) && popupData.visible ) {
              setPopupData(prev => ({name: '', iframe: '', visible: false}))
          }
      }
      document.addEventListener("mousedown", handleClickOutsideTab)
      return () => {
          document.removeEventListener("mousedown", handleClickOutsideTab)
      }
      }
   , [screenRef, popupRef, popupData.visible])
   const fethUrl = `https://esimus-property.com/app/api/gallery?locale=${lang}&populate[gallery][populate][img][populate][img][fields]=url&populate[galleryNew][*]&populate[galleryNew][populate][arrow][fields]=url&populate[galleryNew][populate][GalleryNewSlides][populate][fields]=url&populate[galleryNew][populate][GalleryNewSlides][populate][slides][fields]=url&populate[galleryNew][populate][logo][fields]=url`

   const handleButtonClick = (name, iframe) => {
      setPopupData(() =>({name, iframe, visible: true}))
   }
   if (error) {
     return <div>Ошибка: {error.message}</div>;
   } else if (!isLoaded) {
     return <div className='fetch-loader'></div>;
   } else {
  return (
<>
<Layout>
<Helmet>
   <title>Gallery | Esimus Property</title>
</Helmet>
   <main>
   <section className="about my-gallery">
      <div className="container" >
            <h2 className="my-gallery__header">{items.data.attributes.galleryNew.header}</h2>
            <p className="my-gallery__subheader">{items.data.attributes.galleryNew.subheader}</p>
            <div className="my-gallery__swiperWrapper">
                  {
                        items.data.attributes.galleryNew.GalleryNewSlides.map(sliderData => <div className="my-gallery__card-with-swiper">
                              <Swiper
                                    key={sliderData.id}
                                    className={`swiper swiper_${sliderData.id}`}
                                    loop={true}
                                    effect={'fade'}
                                    fadeEffect={{
                                          crossFade: true
                                    }}
                                    modules={[Navigation,Pagination,EffectFade]}
                                    pagination={{
                                          clickable: true,
                                          el:`.swiper_${sliderData.id} .swiper-pagination`,
                                          
                                          }}
                                    navigation={{
                                    nextEl: `.swiper_${sliderData.id} .swiper-button-next`,
                                    prevEl: `.swiper_${sliderData.id} .swiper-button-prev`}
                                    } >
                                    {
                                          sliderData.slides.data.map(s => <SwiperSlide key={s.id}>
                                                <div className="my-gallery__slide-container">
                                                      <img src={`https://esimus-property.com/app${s.attributes.url}`} alt="" className="my-gallery__swiper-img" />
                                                      <img src={`https://esimus-property.com/app${items.data.attributes.galleryNew.logo.data.attributes.url}`} alt="" className="my-gallery__img-logo" />
                                                </div>
                                          </SwiperSlide>)
                                    }
                                    <div className="swiper-button-prev" style={{backgroundImage: `url(https://esimus-property.com/app${items.data.attributes.galleryNew.arrow.data.attributes.url})`}}></div>
                                    <div className="swiper-button-next" style={{backgroundImage: `url(https://esimus-property.com/app${items.data.attributes.galleryNew.arrow.data.attributes.url})`}}></div>
                                    <div className="swiper-pagination"></div>
                                    {/* <div style={{backgroundImage: `url('https://esimus-property.com/app${items.data.attributes.galleryNew.logo.data.attributes.url}')`}}></div> */}
                                    
                              </Swiper>
                              <div className="my-gallery__bottom-wrapper">
                                    <p className="my-gallery__swiper-text">{sliderData.name}</p>
                                    <button className="my-gallery__swiper-button" style={lang === 'el-CY' ? {fontSize: '12px'} : {}} onClick={() => handleButtonClick(sliderData.name, sliderData.iframe)}>{items.data.attributes.galleryNew.showOnMap}</button>
                              </div>
                        </div>)
                  }
            </div>
            <div className={`popup${popupData.visible ? '' : '_hidden'}`} ref={popupRef}>
                  <svg onClick={() => setPopupData(() => ({name: '', iframe: '', visible: false}))} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L7 7M7 7L13 13M7 7L13 1M7 7L1 13" stroke="#222222" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                  <h4 className="popup__header">{popupData.name}</h4>
                  <div className="popup__iframe-container">
                        {parse(popupData.iframe)}
                  </div>
                  
            </div>
            <div className={`popup__screen${popupData.visible ? ' popup__screen_open' : ''}`} ref={screenRef}></div>
      </div>
   </section>
</main>
</Layout>
</>

  )
}
}
export default Gallery
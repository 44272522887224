

let getFetch=(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)=>{

   fetch(fethUrl)
      .then(res => res.json())
      .then(
         (result) => {
            setIsLoaded(true);
            setItems(result);
            
         },
         (error) => {
            setIsLoaded(true);
            setError(error);
            console.log(error)
         }
      )
      // .finally(() => setIsLoaded(true))

      return error,isLoaded,items
}

export default getFetch 
import React,{useEffect,useState, useContext} from 'react'
import { LanguageContext } from '../languageContext'
import '../styles/Services.scss'
import { useLoadingContext } from "react-router-loading";
import { Helmet } from 'react-helmet';
import getFetch from './functions/fetching';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import Layout from './Layout';
const Services = () => {
   const loadingContext = useLoadingContext();
   const { lang } = useContext(LanguageContext)
   

   // useEffect(()=>{
      
   //    loadingContext.done();
      
   // },[])
   useEffect(() => {
      fetch(fethUrl)
         .then(res => res.json())
         .then(result => setItems(result))
         .catch(error => {
            setError(error)
            console.log(error)
         })
         .finally(() => {
            setIsLoaded(true)
            loadingContext.done()
         })
    }, [lang])
   const [error, setError] = useState(null);
   const [isLoaded, setIsLoaded] = useState(false);
   const [items, setItems] = useState([]);
   const [activeTab, setActiveTab] = useState(1)
   const fethUrl = `https://esimus-property.com/app/api/services?locale=${lang}&populate[img][populate][fields]&populate[servicesNew][populate][list][text]`

   const handleTab = (tabId) => {
      if (tabId !== activeTab) {
         setActiveTab(tabId)
         document.querySelectorAll('.services__menuItem').forEach(b => b.classList.remove('active'))
         document.querySelector(`.services__menuItem_${tabId}`).classList.add('active')
         document.querySelectorAll('.services__displayItem').forEach(b => {
            if (!b.classList.contains('hidden')) {
               b.classList.add('hidden')
            }
         })
         document.querySelector(`.services__displayItem_${tabId}`).classList.remove('hidden')
      }
    }
   if (error) {
     return <div>Ошибка: {error.message}</div>;
   } else if (!isLoaded) {
     return <div className='fetch-loader'></div>;
   } else {
  return (
<>
<Layout>
<Helmet>
   <title>Services | Esimus Property</title>
</Helmet>
   <main>
   <section className="services">
      <div className="container">
         <img src={`https://esimus-property.com/app${items.data.attributes.img.data.attributes.url}`} alt="" className="services__img" />
         <h3 className="services__header">{items.data.attributes.title}</h3>
         <div className="services__container">
            <div className="services__menu">
               {
                  items.data.attributes.servicesNew.map((s, i) => <div key={s.id} className={`services__menuItem services__menuItem_${s.id}${i === 0 ? ' active' : ''}`} onClick={() => handleTab(s.id)}>{s.title}</div>)
               }
            </div>
            <div className="services__display">
               {
                  items.data.attributes.servicesNew.map((s, i) => <div key={s.id} className={`services__displayItem services__displayItem_${s.id}${i !== 0 ? ' hidden' : ''}`}>
                     <h4>{s.title}</h4>
                     <ul>
                        {s.list.map(l => <li key={l.id}>{l.text}</li>)}
                     </ul>
                  </div>)
               }
            </div>
         </div>
         <div className="services__menuMobile">
            <h3 className="services__mobileHeader">{items.data.attributes.title}</h3>
            <Swiper
               className='swiper'
               modules={[Navigation]}
               navigation={{
                  nextEl: `.swiper-button-next`,
                  prevEl: `.swiper-button-prev`}}
            >
               {                 
                  items.data.attributes.servicesNew.map(s => <SwiperSlide key={s.id}><div key={s.id} className={`services__displayItemMobile`}>
                     <h4>{s.title}</h4>
                     <ul>
                        {s.list.map(l => <li key={l.id}>{l.text}</li>)}
                     </ul>
                  </div></SwiperSlide>)
               }
               <div className="swiper-button-prev"
               // style={{backgroundImage: `url(https://esimus-property.com/app${items.data.attributes.galleryNew.arrow.data.attributes.url})`}}
               >
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M21 8L1 8M1 8L8.5 1M1 8L8.5 15" stroke="#99C455" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
               </div>
               <div className="swiper-button-next"
               // style={{backgroundImage: `url(https://esimus-property.com/app${items.data.attributes.galleryNew.arrow.data.attributes.url})`}}
               >
                  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M1 8L21 8M21 8L13.5 1M21 8L13.5 15" stroke="#99C455" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
               </div>
            </Swiper>
         </div>

      </div>
   </section>
</main>
</Layout>
</>

  )
}
}
export default Services
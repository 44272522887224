import React,{useEffect,useState, useContext} from 'react'
import { LanguageContext } from '../languageContext'
import { useLoadingContext } from "react-router-loading";
import Helmet from 'react-helmet';
import "../styles/Text.scss"
import getFetch from './functions/fetching';
import Layout from './Layout';

const Text = () => {
   const loadingContext = useLoadingContext();
   const { lang } = useContext(LanguageContext)

   useEffect(() => {
      fetch(fethUrl)
         .then(res => res.json())
         .then(result => setItems(result))
         .catch(error => {
            setError(error)
            console.log(error)
         })
         .finally(() => {
            setIsLoaded(true)
            loadingContext.done()
         })
    }, [lang])

   const [error, setError] = useState(null);
   const [isLoaded, setIsLoaded] = useState(false);
   const [items, setItems] = useState([]);
   const fethUrl = `https://esimus-property.com/app/api/text?locale=${lang}&populate[info][fields]&populate[footinfo][fields]`
   
   if (error) {
     return <div>Ошибка: {error.message}</div>;
   } else if (!isLoaded) {
     return <div className='fetch-loader'></div>;
   } else {
   
  return (
   <>
   <Layout>
<Helmet>
   <title>Text | Esimus Property</title>
</Helmet>
   <main>
   <section className="completedSpread">
      <div className="container">
         <div className="completedSpread__text">
            <ul className="completedSpread__text_list">
               {
                  items.data.attributes.info.map(item=>(
                     <li key={item.id}>
                        {item.title && <h3>{item.title}</h3>}
                        <p>
                        {item.info}
                        </p>
                     </li>
                  ))
               }
               
            </ul>
         </div>

         <div className="margin">
            <h4>{items.data.attributes.title2}</h4>
            <ul className="about__list_list">
               {
                  items.data.attributes.footinfo.map(item=>(
                     <li key={item.id}>
                        {item.title && <h3>{item.title}</h3>}
                        <p>
                        {item.info}
                        </p>
                     </li>
                  ))
               }
            </ul>
         </div>

         Sources:
         <a
            href={items.data.attributes.sources}> {items.data.attributes.sources}</a>
      </div>
   </section>
</main>
</Layout>
   </>

  )
}
}
export default Text
import "../styles/Terms.scss";
import { Helmet } from "react-helmet";
import Layout from "./Layout";
import { useLoadingContext } from "react-router-loading";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";

const Cookies = () => {
    const loadingContext = useLoadingContext();
    const [data, setData] = useState({ text: "" });

    const getCookiePage = async () => {
        try {
            const response = await fetch("https://esimus-property.com/app/api/cookie");
            const json = await response.json();
            console.log({json})
            if (json?.data?.attributes?.text) {
                setData({
                    text: json?.data?.attributes?.text,
                });
            } else setData("");
        } catch (error) {
            setData("Server error");
        } finally {
            loadingContext.done();
        }
    };

    useEffect(() => {
        getCookiePage();
    }, []);

    return (
        <>
            <Layout>
                <Helmet>
                    <title>Cookies Policy | Esimus Property</title>
                </Helmet>
                <main className="container page">
                    <Markdown>{data?.text}</Markdown>
                </main>
            </Layout>
        </>
    );
};

export default Cookies;

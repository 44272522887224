import React,{useEffect, useState, useContext} from 'react'
import { LanguageContext } from '../languageContext'
import '../styles/About.scss'
import { useLoadingContext } from "react-router-loading";
import { Helmet } from 'react-helmet';
import Layout from './Layout';
const About = () => {
   const loadingContext = useLoadingContext();
   const { lang } = useContext(LanguageContext)
   const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const fethUrl = `https://esimus-property.com/app/api/about-new?locale=${lang}&populate[overviewImg][populate][fields]&populate[portfolioImg][populate][fields]&populate[missionCard][populate][icon][populate][fields]&populate[reasonsToChoose][text]&populate[AboutPortfolio][populate][points][text]`
    const [activeBtn, setActiveBtn] = useState('mission')

    
    useEffect(() => {
      fetch(fethUrl)
         .then(res => res.json())
         .then(result => setItems(result))
         .catch(error => {
            setError(error)
            console.log(error)
         })
         .finally(() => {
            setIsLoaded(true)
            loadingContext.done()
         })
    }, [lang])

    const handleButton = (btnName) => {
      if (btnName !== activeBtn) {
         setActiveBtn(btnName)
         document.querySelectorAll('.menu__button').forEach(b => b.classList.remove('active'))
         document.querySelector(`.menu__button_${btnName}`).classList.add('active')
         document.querySelectorAll('.menu__output').forEach(b => {
            if (!b.classList.contains('hidden')) {
               b.classList.add('hidden')
            }
         })
         document.querySelector(`.menu__output_${btnName}`).classList.remove('hidden')
      }
    }

    if (error) {
      return <div>Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className='fetch-loader'></div>;
    } else {
  return (
   <>
<Layout>  
<Helmet>
   <title>About us | Esimus Property</title>
</Helmet>
<main >
<section className="about">
   <div className="container">

      <div className="overview">
         <div className="overview__text-container">
            <h3>{items.data.attributes.title}</h3>
            <p className="overview__text">{items.data.attributes.overview}</p>
         </div>
         <div className="overview__image-container">
            <img src={`https://esimus-property.com/app${items.data.attributes.overviewImg.data.attributes.url}`} alt="" className="overview__image" />
         </div> 
      </div>

      <div className="overview portfolio">
         <div className="portfolio__image-container">
            <img src={`https://esimus-property.com/app${items.data.attributes.portfolioImg.data.attributes.url}`} alt="" className="portfolio__image" />
         </div> 
         <div className="portfolio__text-container">
            <p className="portfolio__text">{items.data.attributes.AboutPortfolio.portfolio}</p>
            <ul>
               {items.data.attributes.AboutPortfolio.points.map(p => <li key={p.text}>{p.text}</li>)}
            </ul>
            <p className="portfolio__text">{items.data.attributes.AboutPortfolio.licenses}</p>
         </div>
      </div>

      <div className="success-factor">{items.data.attributes.successFactor}</div>

      <div className="menu">
         <div className="menu__buttons">
            <button className="menu__button menu__button_mission active" onClick={() => handleButton('mission')}>{items.data.attributes.missionBtn}</button>
            <button className="menu__button menu__button_whyChoose" onClick={() => handleButton('whyChoose')}>{items.data.attributes.whyChooseBtn}</button>
         </div>
         <div className="menu__output-container">
            <div className="menu__output menu__output_mission">
               {
                  items.data.attributes.missionCard.map(c => <div key={c.id} className="mission-card">
                     <div className="mission-card__imgContainer">
                        <img src={`https://esimus-property.com/app${c.icon.data.attributes.url}`} />
                     </div>
                     <h4>{c.title}</h4>
                     <p>{c.description}</p>
                  </div>)
               }
            </div>
            <div className="menu__output menu__output_whyChoose hidden">
               {
                  items.data.attributes.reasonsToChoose.map((r, i) => <div key={r.id}>
                     <div className="index">{i + 1}</div>
                     <div className="reason">{r.text}</div>
                  </div>)
               }
            </div>
         </div>
      </div>

   </div>
</section>
</main>
</Layout> 
</>

  )
}
}
export default About
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import '../styles/ScrollToTop.scss'

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const [offset, setOffset ] = useState(0)
  const ref = useRef(null)
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    // window.onload = () => window.scrollTo(0,0)
    // return () => window.scrollTo(0, 0)
    // window.onload(() => window.scrollTo(0,0))
    // window.onload = () => window.scrollTo(0,0)
    // window.scrollTo(0,0)
    return () => toTop()
  }, [pathname])

  useEffect(() => {
    if (ref) {
      if (offset > 300) {
        ref.current.classList.remove('hidden')
      } else {
        ref.current.classList.add('hidden')
      }
    }
  }, [offset])

  const handleScroll = () => {
    setOffset(window.scrollY)
  }

  const toTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  // return null;
  return <div className="to-top" onClick={toTop} ref={ref}>
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 15L6 1M6 1L1 6.25M6 1L11 6.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </div>
}

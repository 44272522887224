import "../styles/Policy.scss";
import { Helmet } from "react-helmet";
import Layout from "./Layout";
import { useLoadingContext } from "react-router-loading";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";

const Policy = () => {
    const loadingContext = useLoadingContext();
    const [data, setData] = useState({ text: "", table: "", text2: "" });

    const getPolicyPage = async () => {
        try {
            const response = await fetch("https://esimus-property.com/app/api/policy");
            const json = await response.json();
            if (json?.data?.attributes?.text) {
                setData({
                    text: json?.data?.attributes?.text,
                    // table: json?.data?.attributes?.table,
                    text2: json?.data?.attributes?.text2,
                });
            } else setData("");
        } catch (error) {
            setData("Server error");
        } finally {
            loadingContext.done();
        }
    };

    useEffect(() => {
        getPolicyPage();
    }, []);

    return (
        <>
            <Layout>
                <Helmet>
                    <title>Privacy Policy | Esimus Property</title>
                </Helmet>
                <main className="container page">
                    <Markdown>{data?.text}</Markdown>
                    {/* <div className={"table"}>
                        {data?.table &&
                            data?.table?.length > 0 &&
                            data?.table.map((row, index) => (
                                <div key={index} className="tableRow">
                                    <div>{row?.title || ""}</div>
                                    <div>{row?.text || ""}</div>
                                </div>
                            ))}
                    </div> */}
                    <Markdown>{data?.text2}</Markdown>
                </main>
            </Layout>
        </>
    );
};

export default Policy;

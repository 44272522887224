import React,{useEffect,useState, useContext} from 'react'
import { LanguageContext } from '../languageContext'
import { useLoadingContext } from "react-router-loading";
import Helmet from 'react-helmet';
import '../styles/GoodToKnow.scss';
import Layout from './Layout';

const GoodToKnow = () => {
   const loadingContext = useLoadingContext();
   const { lang } = useContext(LanguageContext)
      useEffect(() => {
         fetch(fethUrl)
            .then(res => res.json())
            .then(result => setItems(result))
            .catch(error => {
               setError(error)
               console.log(error)
            })
            .finally(() => {
               setIsLoaded(true)
               loadingContext.done()
            })
       }, [lang])

      const [error, setError] = useState(null);
      const [isLoaded, setIsLoaded] = useState(false);
      const [items, setItems] = useState([]);
      const fethUrl = `https://esimus-property.com/app/api/regulations?locale=${lang}&populate[about][fields]&populate[laws][fields]&populate[link][fields]&populate[img][fields]`
      
      if (error) {
        return <div>Ошибка: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className='fetch-loader'></div>;
      } else {
  return (
<>
<Layout>
<Helmet>
   <title>Good to know | Esimus Property</title>
</Helmet>
   <main>
   <section className="about good-to-know">
      <div className="container">
         <div className="good-to-know__top-container">
            <h3 className="good-to-know__top-container-header">{items.data.attributes.title}</h3>
            <div className="good-to-know__top-container-img" style={{backgroundImage: `url("https://esimus-property.com/app${items.data.attributes.img.data.attributes.url}")`}} />
            <div className="good-to-know__top-container-text-block">
               <p className="service__subtitle">{items.data.attributes.subtitle1.replace('- ', '')}</p>
               <div className="about__text">
                  <ul className="about__text_list">
                     {
                        items.data.attributes.about.map(item=>(
                           <li key={item.id}>
                           <p>
                              {item.text}
                              
                              {item.link && <a href={item.link} target="_blank"> {items.data.attributes.linkText}</a>}
                              .
                           </p>
                        </li>
                        ))
                     }

                  </ul>
               </div>
            </div>
         </div>
         <div className="about__list">
            <p className="service__subtitle">
            {items.data.attributes.subtitle2.replace('- ', '')}
            </p>
            <ul className="about__list_list">
               {  
                  items.data.attributes.laws.map(item=>(
                     <li key={item.id}>
                        <p>
                           {item.text}
                        </p>
                     </li>
                  ))
               }
               
            </ul>
         </div>
         <div className="good-to-know__links">
            <p className="service__subtitle">{items.data.attributes.subtitle3.replace('- ', '')}</p>
            <ul className="about__list_list">

               {  
                  items.data.attributes.link.map(item=>(
                     <li key={item.id}>
                        <p>
                           <a href={item.link} target="_blank">{item.text}</a>
                        </p>
                     </li>
                  ))
               }
               
            </ul>
         </div>
      </div>
   </section>
</main>
</Layout>
</>

  )
}
}
export default GoodToKnow
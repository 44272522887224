import React,{useEffect,useState, useContext} from 'react'
import { LanguageContext } from '../languageContext'
import '../styles/Main.scss';
import { useLoadingContext } from "react-router-loading";
import {Helmet} from "react-helmet";
import { topbar } from "react-router-loading";
import { Link } from "react-router-dom";
import Layout from './Layout';
import getFetch from './functions/fetching';
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/css"


topbar.config({
    autoRun: true,
    barThickness: 5,
    barColors: {
        0: 'rgba(153, 196, 85, .3)',
        .3: 'rgba(153, 196, 85, 1)',
        1.0: 'rgba(153, 196, 85,  .7)'
    },
    shadowBlur: 5,
    shadowColor: 'green',
    className: 'topbar'
});
const Main = ({imgLoaded}) => {
   
   const loadingContext = useLoadingContext();
   const { lang } = useContext(LanguageContext)

   useEffect(() => {
      fetch(fethUrl)
         .then(res => res.json())
         .then(result => setItems(result))
         .catch(error => {
            setError(error)
            console.log(error)
         })
         .finally(() => {
            setIsLoaded(true)
            loadingContext.done()
         })
    }, [lang])

   const [error, setError] = useState(null);
   const [isLoaded, setIsLoaded] = useState(false);
   const [items, setItems] = useState([]);
   const fethUrl = `https://esimus-property.com/app/api/main?locale=${lang}&populate[news][populate][imgnew][fields]=url&populate[news][populate][link][fields]&populate[legislation][populate][background][fields]=url&populate[legislation][populate][link][fields]&populate[reqlink][populate][imgnew][fields]=url&populate[NewMainTop][populate][background][title]=url`
   
   if (error) {
     return <div>Ошибка: {error.message}</div>;
   } else if (!isLoaded) {
     return <div className='fetch-loader'></div>;
   } else {
   return (
<>
<Layout>
<Helmet>
   <title>{lang === 'ru' ? 'Esimus Service - Управление недвижимостью на Кипре' : 'Esimus Service - Property management in Cyprus'}</title>
</Helmet>

   <main>
   
      <section className="main">
         <div className="imgContainer">
            <img onLoad={loadingContext.done}  src={"https://esimus-property.com/app"+items.data.attributes.NewMainTop.background.data.attributes.url} alt="main" />
            <h1 className="main__text">{items.data.attributes.NewMainTop.title}</h1>
         </div>
         
      </section> 
      <section className="news">
         <div className="container">
            <h3>{items.data.attributes.title}</h3>
            <div className="news__row">
               {
                  items.data.attributes.news.map(item=>(
                     <div key={item.id} className="news__col">
                     <div className="news__item">
                        <img 
                           src={"https://esimus-property.com/app"+item.imgnew.data.attributes.url}
                           alt="img"
                        />
                        <div className="news__inner-container">
                           <h4>{item.title}</h4>
                           <p>
                              {item.description}
                           </p>
                           <div className="news__item_link">
                              <Link to={item.link.link}>{item.link.text}</Link>
                           </div>
                        </div>
                     </div>
                  </div>
                  ))
               }
            </div>
         </div>
      </section> 
      <section className="legislation">
         <div className="container">
            <div className="legislation__body">
               <img src={"https://esimus-property.com/app"+items.data.attributes.legislation.background.data.attributes.url} alt="legislation" />
               <div className="legislation__text">
                  <h2>
                     {items.data.attributes.legislation.title}
                  </h2>
                  <a
                     href={items.data.attributes.legislation.link.link}
                     >{items.data.attributes.legislation.link.text}</a>
               </div>
            </div>
         </div>
      </section> 
      <section className="links">
         <div className="container">
            <h3>{items.data.attributes.lasttitle}</h3>
            {/* <div className="links__row">
               {
                  items.data.attributes.reqlink.map(item=>(
                     <div key={item.id} className="links__col">
                                    
                     <Link to={item.link} className="links__item">
                        <img src={"https://esimus-property.com/app"+item.imgnew.data.attributes.url} alt="link" />
                        <p>{item.text}</p>
                        </Link>
                  </div>
                  ))
               }
               
            </div> */}
            <Swiper
               slidesPerView={'auto'}
               spaceBetween={20}
               breakpoints={{
                  840: {
                     slidesPerView: 4
                  }
               }}
            >
               {
                  items.data.attributes.reqlink.map(item=>(
                     <SwiperSlide key={item.id}> <div className="links__col">
                                    
                     <Link to={item.link} className="links__item">
                        <img src={"https://esimus-property.com/app"+item.imgnew.data.attributes.url} alt="link" />
                        <p>{item.text}</p>
                        </Link>
                  </div>
                  </SwiperSlide>
                  ))
               }
            </Swiper>
         </div>
      </section> 
   </main>
   </Layout>
</>

  )
}
}
export default Main
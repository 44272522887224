import { useState, useEffect }  from 'react'

export default function useLang() {
    const [lang, setLang] = useState(localStorage.getItem('lang') ?? 'en')
    // const [lang, setLang] = useState('en')
  
    useEffect(() => {
        document.addEventListener('LANG_CHANGED', handleLangChange)
        return () => document.removeEventListener('LANG_CHANGED', handleLangChange)
    }, [])

    const handleLangChange = (e) => {
        setLang(e.detail.lang)
    }

    return lang
}

